.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-text-color {
  color: black;
}

.Subtitle {
  margin-top: 0px;
  margin-bottom: 30px;
}

.Title {
  margin-top: 0px;
  margin-bottom: 0px;
}

table {
  width: 400px;
}

table > tr:hover {
  box-shadow: 0 0 15px 1px black;
  border-radius: 15px;
  transition-duration: .4s;
}

table > tr {
  height: 55px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
